import {
} from 'assets/images/svg-icons';
import DisplayStarRating from 'components/shared/display-star-rating';
import Loader from 'components/shared/loader';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { getReview } from 'services/jobs';

const ReviewModal = ({
  showReview, toggle, jobData,
}) => {
  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReview(jobData?.jobId).then((res) => {
      setReviews(res?.data);
    }).finally(() => {
      setLoading(false);
    });
  }, [jobData?.jobId]);

  return (
    <Modal
      isOpen={showReview}
      toggle={toggle}
      centered
      className="review-modal"
    >
      <ModalHeader toggle={toggle}>
        Review for Job&nbsp;
        <Link to="#">
          #
          {jobData?.jobNo}
        </Link>
      </ModalHeader>
      <ModalBody>
        {loading ? <Loader />
          : reviews?.map((review) => (
            <div
              className="tenant-review-card"
              key={review?.tenant_contact_review_request_id}
            >
              <div className="rating-wrapper">
                <DisplayStarRating
                  ratingValue={Number(review?.rating)}
                  totalStars={5}
                  rectFill="#F3F7FF"
                />
              </div>
              <h6>
                <i>{review?.review_text || ''}</i>
              </h6>
              <h5>
                {`${review?.tenant_name ?? ''} ${review?.rating ? `(${parseFloat(review?.rating).toFixed(2).replace(/\.?0+$/, '')}/5)` : ''}`}
              </h5>
              <p>{review?.created_at ?? ''}</p>
            </div>
          ))}
      </ModalBody>
    </Modal>
  );
};
export default ReviewModal;
