import { MyProfileHeaderIcon, UploadIcon } from 'assets/images/svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col, Form, FormGroup, InputGroup, Label, Row,
} from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FileUploader from 'components/shared/form-fields/file-uploader';
import Loader from 'components/shared/loader';

import { Link, useHistory } from 'react-router-dom';
import CustomInputField from 'components/shared/form-fields/custom-input-field';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';
import SelectField from 'components/shared/form-fields/select-field';
import { LookupContext } from 'context/lookup-context/lookup-context';
import { components } from 'react-select';
import { getCompanyProfile, updateCompanyProfile } from 'services/profile';
import { isArray, isEmpty } from 'lodash';
import { generateDropdownOptions } from 'helpers/utils';
import { uploadAttachment } from 'services/attachment';
import { AuthContext } from 'context/auth-context/auth-context';
import catchHandler from 'helpers/catch-handler';
import ImageCropModal from './image-crop-modal';
import PublicApiModal from './public-api-modal';

const schema = Yup.object().shape({
  name: Yup.string().nullable()
    .required('Company Name is required'),
  address: Yup.string().nullable()
    .required('Address is required'),
  city: Yup.string().nullable()
    .required('City is required'),
  state: Yup.object().nullable().required('state is required'),
  zip_code: Yup.string().nullable().required('Zip is required'),
});

const buildFormData = (companyDetails) => {
  const formData = {
    name: companyDetails?.label || '',
    address: companyDetails?.address || '',
    apt_suit: companyDetails?.apt_suit || '',
    city: companyDetails?.city || '',
    ...(companyDetails?.state
      ? {
        state: {
          value: companyDetails.state?.state_code,
          label: companyDetails.state?.state_name,
        },
      }
      : ''),
    zip_code: companyDetails?.zip_code || '',
    client_id: companyDetails?.public_api_info?.client_id || '',
    client_secret: companyDetails?.public_api_info?.client_secret || '',
    webhook_secret: companyDetails?.public_api_info?.webhook_secret || '',
  };
  return formData;
};

const Placeholder = (props) => <components.Placeholder {...props} />;

const CompanyProfile = () => {
  const history = useHistory();
  const lookupData = useContext(LookupContext);
  const [userDetails, setUserDetails] = useContext(AuthContext);

  const [isLoading, setLoading] = useState(false);
  const [profilePicUrl, setProfilepicUrl] = useState();
  const [showImageCropModal, setImageCropModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  const [oldProfilePicUrl, setOldProfilePicUrl] = useState();
  const [attachmentData, setAttachmentData] = useState();
  const [isUpdateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [showPublicAPIModal, setShowPublicAPIModal] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: buildFormData(companyDetails),
    resolver: yupResolver(schema),
  });

  const {
    reset, watch, setValue, setError,
  } = methods;
  const profilePicture = watch('profilePicture');
  const clientId = watch('client_id');
  const clientSecret = watch('client_secret');
  const webhookSecret = watch('webhook_secret');

  useEffect(() => {
    if (isArray(profilePicture)) {
      const formData = new FormData();
      formData.append('file', profilePicture[0]);
      formData.append('entity_type', 'partner');
      setLoading(true);
      uploadAttachment(formData).then((res) => {
        setAttachmentData(res?.data);
        setOldProfilePicUrl(profilePicture);
        setProfilepicUrl(res?.data?.attachment_url);
        setImageCropModal(true);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [profilePicture]);

  const profileUpdate = (params) => {
    if (!isEmpty(params.attachment)) {
      setLoading(true);
    }
    setImageCropModal(false);
    updateCompanyProfile(params).then((res) => {
      if (profilePicture) {
        setValue('profilePicture', '');
      }
      setUserDetails((prev) => ({
        ...prev,
        partner: {
          ...prev.partner,
          partner_logo_url: res?.data?.partner_logo_url,
          label: res?.data?.label,
        },
      }));
      reset(buildFormData(res?.data));
      setProfilepicUrl(res?.data?.partner_logo_url);
      setLoading(false);
      setUpdateButtonLoading(false);
    }).catch((error) => {
      catchHandler(error, setError, true);
      setLoading(false);
      setUpdateButtonLoading(false);
    });
  };

  const onSubmit = (formData) => {
    const payload = {
      address: formData?.address,
      name: formData?.name,
      apt_suit: formData?.apt_suit,
      city: formData?.city,
      state: formData?.state?.value,
      zip_code: formData?.zip_code,
    };
    setUpdateButtonLoading(true);
    profileUpdate(payload);
  };

  useEffect(() => {
    getCompanyProfile().then((res) => {
      setCompanyDetails(res?.data);
      setProfilepicUrl(res?.data?.partner_logo_url);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    if (!isEmpty(companyDetails)) {
      reset(buildFormData(companyDetails));
    }
  }, [reset, companyDetails]);

  useEffect(() => {
    if (userDetails?.property_manager_type === 'employee') {
      history.push('/');
    }
  }, [userDetails, history]);

  return (
    <React.Fragment>
      <div className="page-header d-xs-flex d-sm-flex d-md-flex ">
        <span>
          <MyProfileHeaderIcon />
        </span>
        <h3>Company Profile</h3>
      </div>
      <div className="data-card my-profile-card">
        <Row>
          <Col>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Row>
                  <Col xs="12">
                    <div className="info-wrapper">
                      <div className="info-wrapper--header sub-account">
                        <h3 className="header3">General Info</h3>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="8" xl="7">
                    <FormGroup row>
                      <Col xs="12" className="form-group">
                        <Label for="">Company Name</Label>
                        <CustomInputField
                          className="form-control"
                          name="name"
                        />
                      </Col>
                      {companyDetails?.public_api_access_enabled ? (
                        <React.Fragment>
                          <Col xs="12">
                            <div className="info-wrapper">
                              <div className="info-wrapper--header sub-account">
                                <h3 className="header3">Public API Info</h3>
                              </div>
                            </div>
                          </Col>
                          {clientId ? (
                            <Col xs="12" className="form-group">
                              <Label>Client ID</Label>
                              <CustomInputField name="client_id" readOnly />
                            </Col>
                          ) : ''}
                          {clientSecret ? (
                            <Col xs="12" className="form-group">
                              <Label>Client Secret</Label>
                              <InputGroup>
                                <CustomInputField name="client_secret" readOnly />
                                {/* <InputGroupText className="custom-tooltip">
                              i
                            </InputGroupText> */}
                              </InputGroup>
                            </Col>
                          ) : ''}
                          {webhookSecret ? (
                            <Col xs="12" className="form-group">
                              <Label>Webhook Secret</Label>
                              <InputGroup>
                                <CustomInputField name="webhook_secret" readOnly />
                              </InputGroup>
                            </Col>
                          ) : ''}
                          <Col xs="12">
                            <Button
                              onClick={() => setShowPublicAPIModal(true)}
                              color="primary"
                            >
                              {clientId ? 'Regenerate API Key' : 'Activate Public API'}
                            </Button>
                          </Col>
                        </React.Fragment>
                      ) : ''}
                    </FormGroup>
                  </Col>
                  <Col xs="12" lg="4" xl={{ size: 3, offset: 1 }}>
                    <FileUploader
                      name="profilePicture"
                      uploadBoundary={(
                        <div className="logo-uploader">
                          <div className="logo-uploader-data">
                            {
                              isLoading ? (
                                <Loader />
                              )
                                : (
                                  <React.Fragment>
                                    {profilePicUrl && (
                                      <div>
                                        <div className="uploaded-img-wrap">
                                          <img src={profilePicUrl} alt="" />
                                        </div>
                                        <div className="upload-data">
                                          <UploadIcon />
                                          <div className="logo">Company Logo</div>
                                          <p>
                                            <Link to="#">Browse</Link>
                                            {' '}
                                            or Drag & Drop
                                            {' '}
                                            <br className="d-none d-md-block" />
                                            Picture to Upload
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    <React.Fragment>
                                      <UploadIcon />
                                      <div className="logo">Company Logo</div>
                                      <p>
                                        <Link to="#">Browse</Link>
                                        {' '}
                                        or Drag & Drop
                                        {' '}
                                        <br className="d-none d-md-block" />
                                        Picture to Upload
                                      </p>
                                    </React.Fragment>
                                  </React.Fragment>
                                )
                            }
                          </div>
                        </div>
                      )}
                    />
                  </Col>
                </Row>
                {/* <FormGroup row>
                  <Col><CustomInputField name="client_id" /></Col>
                  <InputGroup>
                    <CustomInputField name="client_secret" />
                    <InputGroupText>
                      i
                    </InputGroupText>
                  </InputGroup>
                  <Col />
                </FormGroup> */}
                {/* {!userDetails?.public_api_access_enabled ? (
                  <Row>
                    <Col>
                      <Button onClick={() => setShowPublicAPIModal(true)}>Test Button</Button>
                    </Col>
                  </Row>
                ) : ''} */}

                <Row>
                  <Col className="mt-4">
                    <FormGroup row>
                      <Col>
                        <div className="info-wrapper--header sub-account">
                          <h3 className="header3">Company Address</h3>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col lg="6">
                        <Label for="">Street Address</Label>
                        <CustomInputField
                          className="form-control"
                          name="address"
                        />
                      </Col>
                      <Col lg="2">
                        <Label for="">Suite/Apt/Etc</Label>
                        <CustomInputField
                          className="form-control"
                          name="apt_suit"
                        />
                      </Col>
                      <Col lg="4">
                        <Label for="">City</Label>
                        <CustomInputField
                          className="form-control"
                          name="city"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col lg="4">
                        <Label for="">State</Label>
                        <SelectField
                          name="state"
                          options={generateDropdownOptions(lookupData?.states, 'state_code', 'state_name')}
                          className="global-select"
                          components={{ Placeholder }}
                          placeholder="Select State"
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#A6A6A6',
                            }),
                          }}
                        />
                      </Col>
                      <Col lg="4">
                        <Label for="">Zip</Label>
                        <CustomInputField
                          className="form-control"
                          name="zip_code"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <ButtonWithLoader
                          color="primary"
                          type="submit"
                          isLoading={isUpdateButtonLoading}
                        >
                          Update
                        </ButtonWithLoader>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </Col>
        </Row>
      </div>
      {
        showImageCropModal ? (
          <ImageCropModal
            profilePicture={profilePicture}
            showImageCropModal={showImageCropModal}
            attachmentId={attachmentData?.attachment_id}
            toggle={() => setImageCropModal(false)}
            profileUpdate={profileUpdate}
            oldProfilePicUrl={oldProfilePicUrl}
          />
        ) : ''
      }
      {showPublicAPIModal ? (
        <PublicApiModal
          closeModal={() => setShowPublicAPIModal(false)}
          onSuccessHandler={(response) => {
            setCompanyDetails((prev) => ({
              ...prev,
              public_api_info: {
                ...(prev?.public_api_info || {}),
                client_id: response?.client_id ?? '',
                client_secret: response?.client_secret ?? '',
                webhook_secret: response?.webhook_secret ?? '',
              },
              public_api_access_enabled: response?.public_api_access_enabled || 1,
            }));
            // setShowPublicAPIModal(false);
          }}
        />
      ) : ''}
    </React.Fragment>
  );
};

export default CompanyProfile;
