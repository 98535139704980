import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomInputField from 'components/shared/form-fields/custom-input-field';
import ButtonWithLoader from 'components/shared/form-fields/loading-button';

import catchHandler from 'helpers/catch-handler';
import { enablePublicAPI } from 'services/profile';

const validationSchema = yup.object().shape({
  public_api_webhook_callback_url: yup.string().trim().required('This field is required'),
});

const PublicApiModal = ({ closeModal, onSuccessHandler }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      public_api_webhook_callback_url: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, setError } = methods;

  const onFormSubmitHandler = (formData) => {
    const payload = {
      ...formData,
      public_api_access_enabled: 1,
    };
    setIsFormSubmitting(true);
    enablePublicAPI(payload).then((response) => {
      setIsFormSubmitting(false);
      onSuccessHandler(response?.data);
      closeModal();
    }).catch((error) => {
      setIsFormSubmitting(false);
      catchHandler(error, setError, true);
    });
  };

  return (
    <Modal
      isOpen
      centered
      toggle={closeModal}
      className="global-modal global-update-modal"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmitHandler)}>
          <ModalHeader toggle={closeModal}>
            Enter Call Back URL
          </ModalHeader>
          <ModalBody>
            <div className="divider" />
            <Row>
              <Col>
                <FormGroup>
                  <Label>Call Back Url</Label>
                  <CustomInputField
                    className="form-control"
                    name="public_api_webhook_callback_url"
                    placeholder="Enter callback URL"
                  />
                </FormGroup>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModal}>Cancel</Button>
            <ButtonWithLoader isLoading={isFormSubmitting} type="submit" color="primary">Submit</ButtonWithLoader>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default PublicApiModal;
