import React from 'react';

const StarRatingIcon = ({ inputValue, rectFill = 'white' }) => {
  const getWidth = () => {
    switch (inputValue) {
      case 1:
        return '20';
      case 2:
        return '18';
      case 3:
        return '16';
      case 4:
        return '14';
      case 5:
        return '12';
      case 6:
        return '10';
      case 7:
        return '8';
      case 8:
        return '6';
      case 9:
        return '4';
      case 10:
        return '0';
      default:
        return '23';
    }
  };

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6073 8.20733C22.4581 7.74836 22.051 7.42342 21.5713 7.38001L15.0281 6.78598L12.4422 0.731862C12.2513 0.287073 11.8169 0 11.3333 0C10.8498 0 10.4152 0.287073 10.2255 0.731862L7.6396 6.78598L1.09539 7.38001C0.615663 7.42428 0.209438 7.74923 0.0593305 8.20733C-0.0899126 8.66631 0.0479167 9.16972 0.410735 9.48792L5.35686 13.825L3.89849 20.2481C3.79179 20.7204 3.9751 21.2088 4.36698 21.492C4.57761 21.6451 4.82508 21.7215 5.07342 21.7215C5.28682 21.7215 5.50039 21.6648 5.69114 21.5507L11.3333 18.1771L16.9745 21.5507C17.3883 21.798 17.9087 21.7753 18.2997 21.492C18.6915 21.2088 18.8749 20.7204 18.7682 20.2481L17.3098 13.825L22.2559 9.48792C22.6186 9.16972 22.7566 8.66734 22.6073 8.20733V8.20733Z" fill="#FF8E00" />
      <rect width={getWidth()} height="22" transform="matrix(-1 0 0 1 23 0)" fill={rectFill} fillOpacity="0.7" />
    </svg>
  );
};

StarRatingIcon.defaultProps = {
  rectFill: 'white',
};

export default StarRatingIcon;
