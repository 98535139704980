import React from 'react';
import { generateRandomString } from 'helpers/utils';
import StarRatingIcon from './star-rating-icon';

const DisplayStarRating = (props) => {
  const { ratingValue, totalStars, rectFill } = props;
  const ratingFloatValue = Math.abs(ratingValue) || 0;

  const roundedRating = parseFloat(ratingFloatValue.toFixed(1));
  const totalFilledStars = Math.trunc(roundedRating);
  const totalStarValue = totalStars ?? 5;

  const decimalValue = Math.round((roundedRating - totalFilledStars) * 10);

  const getStarWidthCaseInput = (starPosition) => {
    if (totalFilledStars >= totalStarValue) {
      return 10;
    }
    if (totalFilledStars >= starPosition) {
      return 10;
    }
    if (starPosition === (totalFilledStars + 1)) {
      return decimalValue;
    }
    return 0;
  };

  return ([...Array(totalStarValue)]?.map((item, i) => (
    <StarRatingIcon
      inputValue={getStarWidthCaseInput(i + 1)}
      key={generateRandomString()}
      rectFill={rectFill}
    />
  ))
  );
};

export default DisplayStarRating;
