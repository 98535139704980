import ReactTooltip from 'react-tooltip';
import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { pick } from 'lodash';

import { getExportJobs, getJobs } from 'services/jobs';
import {
  ClosedJobsHeaderIcons,
  StatusCompletedIcon,
  ExcelIcon,
  StatusVoidIcon,
  NonReviewICon,
  ReviewICon,
} from 'assets/images/svg-icons';

import ListingComponent from 'components/shared/listing-component';
import JobDetails from 'components/shared/job-details';

import Filter from 'components/shared/filter/filter';
import HeaderComponent from 'components/shared/header-component';

import { LookupContext } from 'context/lookup-context/lookup-context';
import { logHere, openInANewTab } from 'helpers/utils';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';
import ReviewModal from 'components/closed-jobs/review-modal';
import catchHandler from 'helpers/catch-handler';
import ExportModal from 'components/shared/modals/export-modal';
import TooltipWrapper from 'components/shared/TooltipWrapper';

const residentIssuesBanner = {
  partially: {
    showResidentProblem: 'partially caused',
    residentProblemBannerClassName: 'partially-caused',
  },
  yes: {
    showResidentProblem: 'caused',
    residentProblemBannerClassName: 'fully-caused',
  },
};

const ClosedJobs = (props) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const { location } = props;
  const startDate = location?.state?.startDate || moment().subtract(29, 'days').format('YYYY-MM-DD');
  const endDate = location?.state?.endDate || moment().format('YYYY-MM-DD');
  const {
    service_categories: serviceCategories,
    marketCities,
    serviceCategoriesLookupObj,
  } = useContext(LookupContext);
  const [filter, setFilter] = useState({
    from_date: startDate,
    to_date: endDate,
    ...(location?.state?.show_reviewed_jobs ? { show_reviewed_jobs: 1 } : {}),
  });
  const [showDescription, setDescription] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState();
  const [exportJobModal, setExportJobModal] = useState(false);
  const [showReview, setReview] = useState(false);
  const [jobData, setJobData] = useState();
  const [exportLoading, setExportLoading] = useState();

  const fetchClosedJobs = useCallback((params) => {
    // Needed to set to avoid expanded items being called again on page change
    setData([]);
    setLoading(true);
    getJobs('closed', params).then((res) => {
      setLoading(false);
      setData(res?.data?.data);
      setMeta(res?.data?.meta);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    fetchClosedJobs(filter);
  }, [fetchClosedJobs, filter]);

  const showDescriptionHandler = (jobId) => {
    setSelectedJobId(jobId);
    setDescription(true);
  };

  const onLinkClickHandler = (job) => {
    if (job?.external_job_source?.link) {
      openInANewTab(job?.external_job_source?.link);
    } else {
      showDescriptionHandler(job.job_id);
    }
  };

  const getClassName = (status) => {
    switch (status) {
      case 'completed': return 'completed';
      case 'not-completed': return 'cancelled';
      case 'quote-accepted': return 'quote-completed';
      default: return '';
    }
  };

  const reviewHandler = (item) => {
    if (item.is_reviewed) {
      setJobData({
        jobId: item?.job_id,
        jobNo: item?.job_no,
      });
      setReview(true);
    }
  };

  const exportHandler = (params, setError) => {
    setExportLoading(true);
    getExportJobs(params).then((response) => {
      logHere('export Jobs', response);
      openInANewTab(response.data?.export_url);
      setExportLoading(false);
      setExportJobModal(false);
    }).catch((error) => {
      setExportLoading(false);
      catchHandler(error, setError, true);
      setExportJobModal(false);
    });
  };

  const url = useLocation();

  useEffect(() => {
    if (url?.state?.job_no) {
      setFilter({
        search_keyword: url?.state?.job_no,
      });
      window.history.replaceState({}, document.title);
    }
  }, [url?.state]);

  return (
    <React.Fragment>
      <HeaderComponent>
        <button className="excel-btn" type="button" onClick={() => setExportJobModal(true)}>
          <ExcelIcon className="mr-1" />
          Export to Excel
        </button>
      </HeaderComponent>
      <Filter
        title="Closed Jobs"
        HeaderIcon={() => <ClosedJobsHeaderIcons />}
        filters={[{
          filter_type: 'custom-input',
          name: 'search_keyword',
          placeholder: 'Search by Address/ Job / Tenant',
          type: 'text',
          inputProps: {
            className: 'form-control form-control-closed-jobs',
          },
        },
        {
          filter_type: 'select',
          name: 'service_category',
          options: serviceCategories ?? [],
          placeholder: 'Filter by Service',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        },
        {
          filter_type: 'select',
          name: 'market_city',
          options: marketCities ?? [],
          placeholder: 'All Markets',
          type: 'text',
          inputProps: {
            className: 'global-select global-select-resize',
          },
        },
        {
          filter_type: 'date-range',
          name: 'dateRange',
          from_name: 'from_date',
          to_name: 'to_date',
        },
        {
          filter_type: 'custom-input',
          name: 'resident_caused_problem',
          type: 'checkbox',
          label: 'Resident Caused',
          inputProps: {
            className: 'resident-caused-checkbox',
          },
        },
        {
          filter_type: 'clear',
          name: 'clear-filter',
        },
        ]}
        clearStateValues={{
          dateRange: {
            startDate,
            endDate,
            type: 'date-range',
          },
        }}
        defaultValues={{
          search_keyword: url?.state?.job_no,
          uniqueKey: url?.key,
          dateRange: {
            startDate,
            endDate,
            type: 'date-range',
          },
          resident_caused_problem: false,
        }}
        onFilter={(params) => setFilter((prev) => ({
          ...(pick(prev, ['per_page', 'sort'])),
          page: 1,
          ...params,
        }))}
      />
      <div className="data-card p-0 custom-data-card">
        <ListingComponent
          isLoading={loading}
          mappings={[
            {
              key: 'expand',
              label: '',
              type: 'expand',
            },
            {
              key: 'job_no',
              label: 'Lula Job #',
              render: (item) => (
                <React.Fragment>
                  <div className="services">
                    {
                      serviceCategoriesLookupObj?.[item.service_category_id]?.logo && (
                        <img
                          src={serviceCategoriesLookupObj[item.service_category_id].logo}
                          alt=""
                          width="27px"
                          height="27px"
                          className="d-block d-sm-none"
                        />

                      )
                    }
                    <Link to="#" onClick={() => showDescriptionHandler(item.job_id)}>
                      {item.job_no}
                    </Link>
                    {['partially', 'yes']?.includes(item?.is_resident_caused_problem) ? (
                      <TooltipWrapper renderTooltip={(isTooltipOpen, setIsTooltipOpen) => (
                        <React.Fragment>
                          <span
                            className={`resident-problem-icon resident-problem--${item?.is_resident_caused_problem}`}
                            data-for={`job-resident-${item?.job_id}`}
                            data-tip={` Resident ${residentIssuesBanner?.[item?.is_resident_caused_problem]?.showResidentProblem} the problem`}
                            onMouseEnter={() => setIsTooltipOpen(true)}
                            onMouseLeave={() => setIsTooltipOpen(false)}
                          >
                            R
                          </span>
                          {isTooltipOpen ? (
                            <ReactTooltip
                              className="resident-caused-tooltip"
                              id={`job-resident-${item?.job_id}`}
                            />
                          ) : '' }
                        </React.Fragment>
                      )}
                      />
                    ) : ''}
                  </div>
                </React.Fragment>
              ),
            },
            {
              key: 'external_job_source',
              className: 'd-none d-xl-table-cell cw-25',
              label: 'PM Work Order #',
              render: (item) => (
                <React.Fragment>
                  {item.external_job_source
                    ? (
                      <React.Fragment>
                        <Link to="#" className="pm-link" onClick={() => onLinkClickHandler(item)}>
                          {item.external_job_source.reference_no ? (
                            <React.Fragment>
                              #
                              {item.external_job_source.reference_no}
                            </React.Fragment>
                          ) : ''}
                        </Link>
                        {' '}
                        <span className="pm-title">{item.external_job_source.label}</span>
                      </React.Fragment>
                    )
                    : '–' }
                </React.Fragment>
              ),
            },
            {
              key: 'service_category',
              label: 'SERVICE TYPE',
              className: 'd-none d-sm-table-cell',
              render: (item) => (
                <div className="services">
                  {
                    serviceCategoriesLookupObj?.[item.service_category_id]?.logo && (
                      <img
                        src={serviceCategoriesLookupObj[item.service_category_id].logo}
                        alt=""
                        width="27px"
                        height="27px"
                      />

                    )
                  }
                  {item.service_category}
                </div>
              ),
            },
            {
              key: 'address',
              label: 'PROPERTY ADDRESS',
              className: 'd-none d-xl-table-cell',
            },
            {
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-none d-sm-table-cell sw-9',
              render: (item) => (
                <div className={`d-none d-sm-block status ${getClassName(item.status_group)}`}>
                  {item.status_group === 'not-completed' ? <StatusVoidIcon /> : <StatusCompletedIcon /> }
                  {item.status}
                </div>
              ),
            },
            {
              key: 'bill',
              label: 'BILL',
              sortable: true,
              sortKey: 'bill',
              render: (item) => (
                <React.Fragment>
                  $
                  {item.bill}
                </React.Fragment>
              ),
            },
            {
              id: 'status_mob',
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-table-cell d-sm-none text-center',
              render: (item) => (
                <div className={`d-block d-sm-none status ${getClassName(item.status_group)}`}>
                  {item.status_group === 'not-completed' ? <StatusVoidIcon /> : <StatusCompletedIcon /> }
                </div>
              ),
            },
            {
              key: 'review',
              label: 'Review',
              sortable: false,
              className: 'closed-button-review-col d-none d-md-flex',
              render: (item) => (
                <Link to="#" onClick={() => reviewHandler(item)} className={`${item.is_reviewed ? 'review-added' : 'no-review'}`}>
                  <i>
                    {
                        item.is_reviewed ? <ReviewICon /> : <NonReviewICon />
                      }
                  </i>
                  {
                      item.is_reviewed && (
                        <React.Fragment>
                          {item.tenant_rating}
                          {' '}
                          /
                          5.0
                        </React.Fragment>
                      )
                    }

                </Link>
              ),
            },
          ]}
          dataSource={data}
          meta={meta}
          shouldPaginate
          className="global-table"
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          onSortChange={(sort) => setFilter((prev) => ({ ...prev, sort }))}
          primaryKey="job_id"
          renderExpanded={(job) => <JobDetails job={job} />}
        />
      </div>
      {
        showDescription && (
          <JobDescriptionModal
            showDescription={showDescription}
            toggle={() => setDescription(false)}
            jobId={selectedJobId}
          />
        )
      }
      {exportJobModal ? (
        <ExportModal
          closeModal={() => setExportJobModal(false)}
          exportHandler={exportHandler}
          title="Export Closed Jobs"
          entity="Jobs"
          isLoading={exportLoading}
        />
      ) : ''}
      {
        showReview && (
          <ReviewModal
            showReview={showReview}
            toggle={() => setReview(false)}
            jobData={jobData}
          />
        )
      }
    </React.Fragment>
  );
};
export default ClosedJobs;
